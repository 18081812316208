<template>
  <div>
    <form-section :title="$t('courierSet.paymentsSettings.title')">
      <v-row>
        <v-col>
          <select-field
            v-model="getCustomsClearanceDetail.paymentType"
            :title="$t('courierSet.paymentsSettings.paymentType')"
            :filed-items="paymentTypes"
            :rules="'required'"
          />
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col>
          <text-field
            v-model="getDefaultValues.bankAccountNumber"
            :title="$t('courierSet.authorizationData.iban')"
            :rules="getConfigurationValues.paymentForm === 'P' ? 'max:26|required' : 'max:26'"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.senderId"
            :title="$t('courierSet.senderId')"
            :rules="'max:10|required'"
          />
        </v-col>
      </v-row> -->
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    isNew: { type: Boolean, default: null },
  },
  data: () => ({
    paymentTypes: [
      { id: 0, name: 'Konto' },
      { id: 1, name: 'Pobranie' },
      { id: 3, name: 'Nadawca' },
      { id: 2, name: 'Odbiorca' },
      { id: 4, name: 'Osoba trzecia' },
    ],
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getCustomsClearanceDetail() {
      return this.getConfigurationSet().fedexDefaultValues.shippingChargesPayment;
    },
  },
};
</script>
