import { CodDetail } from './CodDetail';
import { CustomsClearanceDetail } from './CustomsClearanceDetail';

export const FedexDefaultValues = {
  id: '',
  clientId: '',
  tenantId: '',
  configurationName: '',
  clientEntityState: 4,
  codDetail: CodDetail,
  customsClearanceDetail: CustomsClearanceDetail,
  shippingChargesPayment: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    paymentType: 'ACCOUNT',
    responsibleParty: null,
  },
};
