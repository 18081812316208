<template>
  <div>
    <form-section :title="$t('courierSet.authorizationData.title')">
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.key"
            :title="$t('courierSet.key')"
            rules="required"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getConfigurationValues.password"
            :title="$t('courierSet.secretKey')"
            type="password"
            :rules="isNew ? 'required' : ''"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.accountNumber"
            :title="$t('courierSet.accountNumber')"
            :hint="$t('courierSet.accountNumberHint')"
            rules="required"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.urlAddress"
            :title="$t('courierSet.authorizationData.urlAddress')"
            :rules="'required|url'"
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.shipmentMethod.title')">
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.configurationName"
            :title="$t('courierSet.shipmentMethod.configurationName')"
            rules="required|not_custom_shipment"
          />
        </v-col>
        <v-col>
          <select-field
            v-model="getConfigurationValues.courierCode"
            :title="$t('courierSet.shipmentMethod.deliverySpeditor')"
            rules="required"
            :filed-items="SHIPMENT_METHOD_CODES"
            disabled
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationValues.id"
            :title="$t('courierSet.shipmentMethod.configurationId')"
            rules="required"
            :disabled="true"
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.serviceType.title')">
      <v-row>
        <v-col>
          <select-field
            v-model="getConfigurationValues.serviceType"
            :title="$t('courierSet.serviceType.title')"
            rules="required"
            :filed-items="serviceTypes"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <select-field
            v-model="getConfigurationValues.dropoffType"
            :title="$t('courierSet.deliveryMethod')"
            rules="required"
            :filed-items="dropoffTypes"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import { dropoffTypes } from '@/constants/Speditors/Fedex/DropoffTypes';
import { serviceTypes } from '@/constants/Speditors/Fedex/ServiceTypes';
import { SHIPMENT_METHOD_CODES } from '@/constants/ShipmentMethodCodes.js';
import { TEST_FEDEX_URL } from '@/constants/Speditors/testApi.js';
import { PROD_FEDEX_URL } from '@/constants/Speditors/prodApi.js';

export default {
  props: {
    isNew: { type: Boolean, default: false },
  },
  data() {
    return {
      SHIPMENT_METHOD_CODES,
      dropoffTypes,
      serviceTypes,
    };
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getConfigurationValues() {
      return this.getConfigurationSet();
    },
    getUrlAddress() {
      if (process.env.VUE_APP_BUILD_TARGET_ENVIRONMENT === 'production') {
        return `https://${PROD_FEDEX_URL}`;
      }
      return `https://${TEST_FEDEX_URL}`;
    },
  },
  mounted() {
    if (this.isNew) {
      this.getConfigurationValues.id = uuidv4();
      this.getConfigurationValues.urlAddress = this.getUrlAddress;
    }
  },
};
</script>

<style scoped>
.col {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
