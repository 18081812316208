<template>
  <div>
    <form-section :title="$t('courierSet.labelSettings.title')">
      <v-row>
        <v-col>
          <select-field
            v-model="getLabelSpecification.imageType"
            :title="$t('courierSet.imageType')"
            rules="required"
            :filed-items="imageTypes"
          />
        </v-col>
        <v-col>
          <select-field
            v-model="getLabelSpecification.labelFormatType"
            :title="$t('courierSet.labelFormatType')"
            rules="required"
            :filed-items="labelFormatTypes"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <select-field
            v-model="getLabelSpecification.labelStockType"
            :title="$t('courierSet.labelStockType')"
            rules="required"
            :filed-items="getLabelTypes"
          />
        </v-col>
        <v-col>
          <select-field
            v-model="getLabelSpecification.labelPrintingOrientation"
            :title="$t('courierSet.labelPrintingOrientation')"
            rules="required"
            :filed-items="labelPrintingOrientations"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { imageTypes } from '@/constants/Speditors/Fedex/ImageTypes';
import { labelFormatTypes } from '@/constants/Speditors/Fedex/LabelFormatTypes';
import { labelStockTypes, labelPaperTypes } from '@/constants/Speditors/Fedex/LabelStockTypes';
import { labelPrintingOrientations } from '@/constants/Speditors/Fedex/LabelPrintingOrientations';

export default {
  props: {
    isNew: { type: Boolean, default: null },
  },
  data() {
    return {
      imageTypes,
      labelFormatTypes,
      labelPrintingOrientations,
    };
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getLabelSpecification() {
      return this.getConfigurationSet().labelSpecification;
    },
    getLabelTypes() {
      if (
        this.getLabelSpecification.imageType === 'PDF' ||
        this.getLabelSpecification.imageType === 'PNG'
      ) {
        return labelPaperTypes;
      }
      return labelStockTypes;
    },
  },
  watch: {
    'getLabelSpecification.imageType': {
      handler(newValue, oldValue) {
        if (!!oldValue && !!newValue) {
          this.getLabelSpecification.labelStockType = '';
        }
      },
    },
  },
};
</script>
