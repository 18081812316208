export const labelPaperTypes = [
  { id: 'PAPER_4X6', name: 'Etykieta 100x150' },
  { id: 'PAPER_4X675', name: 'Etykieta 100x170' },
  { id: 'PAPER_4X8', name: 'Etykieta 100x200' },
  { id: 'PAPER_4X9', name: 'Etykieta 100x230' },
  { id: 'PAPER_7X475', name: 'Etykieta 180x120' },
  { id: 'PAPER_85X11_BOTTOM_HALF_LABEL', name: 'Etykieta A4 210x290 Dolna połowa etykiety' },
  { id: 'PAPER_85X11_TOP_HALF_LABEL', name: 'Etykieta A4 210x290 Górna połowa etykiety' },
  { id: 'PAPER_LETTER', name: 'List' },
];

export const labelStockTypes = [
  { id: 'STOCK_4X6', name: 'Etykieta 100x150' },
  { id: 'STOCK_4X675', name: 'Etykieta 100x170' },
  { id: 'STOCK_4X675_LEADING_DOC_TAB', name: 'Etykieta 100x170 Lista dokumentów przewozowych' },
  { id: 'STOCK_4X675_TRAILING_DOC_TAB', name: 'Etykieta 100x170 Lista dokumentów załadunkowych' },
  { id: 'STOCK_4X8', name: 'Etykieta 100x200' },
  { id: 'STOCK_4X9', name: 'Etykieta 100x230' },
  { id: 'STOCK_4X9_LEADING_DOC_TAB', name: 'Etykieta 100x230 Lista dokumentów przewozowych' },
  { id: 'STOCK_4X9_TRAILING_DOC_TAB', name: 'Etykieta 100x230 Lista dokumentów załadunkowych' },
  { id: 'STOCK_4X85_TRAILING_DOC_TAB ', name: 'Etykieta 100x210 Lista dokumentów załadunkowych ' },
  { id: 'STOCK_4X105_TRAILING_DOC_TAB ', name: 'Etykieta 100x270 Lista dokumentów załadunkowych ' },
];
