import { render, staticRenderFns } from "./AdditionalServices.vue?vue&type=template&id=746b616b&scoped=true"
import script from "./AdditionalServices.vue?vue&type=script&lang=js"
export * from "./AdditionalServices.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "746b616b",
  null
  
)

export default component.exports