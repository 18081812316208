export const CustomsClearanceDetail = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  paymentType: 'ACCOUNT',
  unitPrice: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    amount: 0,
    currency: '',
  },
  quantity: 0,
  quantityUnits: '',
  customsValue: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    amount: 0,
    currency: '',
  },
  insurance: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    amount: 0,
    currency: 'PLN',
  },
  countryOfManufacture: '',
  description: '',
  name: '',
  isDocumentOnly: true,
  termsOfSale: '',
  shipmentPurpose: '',
};
